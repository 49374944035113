import apiSauce from "./api-sauce";

export class UserApi {

  async user_login(payload): Promise<any> {
    return await apiSauce.apisauce.post("user/signin_email", payload)
  }

  async user_signup(payload): Promise<any> {
    return await apiSauce.apisauce.post("user/signup_email", payload)
  }

  async verify_email(key: string) {
    return await apiSauce.apisauce.post("user/verify_email", {code: key})
  }

  async request_reset_pwd(payload): Promise<any> {
    return await apiSauce.apisauce.post("user/request_pwd_reset", JSON.stringify(payload))
  }

  async reset_pwd(payload): Promise<any> {
    return await apiSauce.apisauce.post("user/pwd_reset", JSON.stringify(payload))
  }
  async save_personal_data(payload): Promise<any> {
    return await apiSauce.apisauce.post("user/save_account_details", JSON.stringify(payload))
  }

  async request_otp(payload): Promise<any>{
    return await apiSauce.apisauce.post("auth/request_otp", JSON.stringify(payload))
  }

  async request_signup_or_signin(payload): Promise<any>{
    return await apiSauce.apisauce.post("auth/validate_otp", JSON.stringify(payload))
  }

  async support_email(payload): Promise<any>{
    return await apiSauce.apisauce.post("user/send_support_email", JSON.stringify(payload))
  }

}
