import {action, makeAutoObservable, runInAction, toJS} from "mobx";
import {makePersistable} from 'mobx-persist-store';
import {SopApi} from "../api/sop-api";
import {safe_get} from "../pages/utils";
import _, {clone, cloneDeep} from "lodash";
import {makeid_alpa_numeric} from "../utils";


import {toast} from "react-toastify";

import sanitizeHtml from 'sanitize-html';
export const sanitizeConf = {
  allowedTags: [],
  allowedClasses: {
    p: [],
  },
  allowedAttributes: {
      'p': [ ]
    }
};

export const stepsArr = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5']

export class SopStore {
  activeStep = 'Step 1';
  isFileCreated=false;
  selecteduniversitiy = null;
  selectedDegree = null;
  selectedCourse = null;
  jsonDataLoaded = false;
  selectedCard = -1;
  cardSelected = false;
  selectedCardDescription = '';
  newComposeObject = {};
  generatedOutput = '';
  loading = false;
  showTemplate = false;
  editor_data = { blocks: [], entityMap: {} };
  editor_id: string = makeid_alpa_numeric(6);
  sopId: '';
  sopName: '';
  isMovingSopDocDialogOpen: false;
  breadCrumbs: [];
  availableCredits = null;
  college_summary = '';
  folderid=null;
  sop_description =
    'Choose the course and university you wish to apply to. Let us know your preferences, including specific programs or departments. This information will help us tailor your SOP to highlight your alignment with their offerings.';
    final_output_screen = false;
    edit_clicked = false;
    slot="";
    slotId="";
  
    @action setSlot(slot) {
      this.slot = slot;
    }
  
    @action setEditClicked(bool) {
      this.edit_clicked = bool;
    }
  
    @action setFinalOutputScreen(bool) {
      this.final_output_screen = bool
    }
    @action setSlotId (id) {
      this.slotId= id
    }
    
  steps = [
    {
      id: 'Step 1',
      name: 'About College',
      href: '#',
      current: true,
      completed: false,
      desc: 'Choose the course and university you wish to apply to. Let us know your preferences, including specific programs or departments. This information will help us tailor your SOP to highlight your alignment with their offerings.',
    },
    {
      id: 'Step 2',
      name: 'Motivation',
      href: '#',
      current: false,
      completed: false,
      desc: 'Share your motivation and inspiration for pursuing this particular course of study. Express your passion, personal experiences, and aspirations that drive your academic goals. Paint a clear picture of why this field resonates with you.',
    },
    {
      id: 'Step 3',
      name: 'Goals/Aspirations',
      href: '#',
      current: false,
      completed: false,
      desc: 'Outline your specific objectives and goals you aim to achieve through this course. Whether its career advancement, research opportunities, or personal development, articulate your ambitions clearly. ',
    },
    {
      id: 'Step 4',
      name: 'Qualification',
      href: '#',
      current: false,
      completed: false,
      desc: 'Highlight your qualifications, skills, and academic background that make you a strong candidate for the chosen course. Showcase your unique strengths, experiences, and achievements that set you apart from other applicants.',
    },
    {
      id: 'Step 5',
      name: 'Contribution',
      href: '#',
      current: false,
      completed: false,
      desc: 'Go above and beyond by sharing how you can contribute to the college or university community beyond your course of study. Emphasize your leadership abilities, community involvement, or any other relevant skills that make you a valuable asset.',
    },
  ];

  defaultSopJsonData = {
    course: {},
    university: {},
    degree: {},
    word_count: '1000',
    reason_for_doing_course: '',
    reason_pursue_course: '',
    reason_for_chosen_university: '',
    reason_for_chosen_country:'',
    motivation: {},
    short_term_goals: '',
    long_term_goals: '',
    qualifications: [],
    contributions: [],
    switching_fields_from_prev_education:"",
    academic_background:"",
    undergraduate_projects:"",
    internships:"",
    work_experience:"",
    curricular_activities:'',
    reasons_for_admissions_committee:'',
    reason_stronger_candidate:'',
    skills:'',
    obstacles_or_hardships:'',
    defaultQualifications:[]
  };

 

  templatesForScreen ={};

  

  selected_text_area = {
    name: '',
    template_id: '',
    template_data: '',
  };
  sopjsonData = { ...this.defaultSopJsonData };

  constructor(private sopApi: SopApi) {
    makeAutoObservable(this);
    makePersistable(this, { name: 'SopStore', properties: [], storage: window.localStorage });
  }

  @action
  setSopDescription(content) {
    this.sop_description = content;
  }

  @action
  updateSopJsonData(key, value) {
    this.sopjsonData = {
      ...this.sopjsonData,
      [key]: value,
    };
  }

  @action
  templateExists(question){

    return (this.templatesForScreen[question] && this.templatesForScreen[question].length > 0) || false;

  }

  @action
  setIsSopDocMovingDialogOpen(payload) {
    this.isMovingSopDocDialogOpen = payload;
  }

  @action
  updateSopJsonDataArr(slot, key, value) {
    const temp = clone(this.sopjsonData);
    safe_get(temp, slot, []).map((item) => {
      if (item.id === key) {
        item.value = value;
      }
      this.sopjsonData = temp;
    });
  }

  @action
  updateSopJsonObj(slot, value) {
    const temp = clone(this.sopjsonData);
    temp[slot] = {};
    if(slot == 'motivation') {
      temp[slot] = {
        key: this.selectedCard,
        value: value,
        cardTitle:this.selectedCardDescription
      };
    }else {
      temp[slot] = {
        key: this.selectedCard,
        value: value,
      };
    }
    this.sopjsonData = temp;
  }

  @action
  updateCurrentStatusForStep(stepName) {
    this.steps.map((step) => {
      if (step.id === stepName) {
        step.current = true;
      } else {
        step.current = false;
      }
    });
  }

  @action
  updateSelectedTextarea(textareaName) {
    console.log(textareaName, 'textAreaname');
  }

  @action
  set_selected_textarea(textareaName, id, content) {
    this.selected_text_area.name = textareaName;
    this.selected_text_area.template_id = id;
    this.selected_text_area.template_data = content;
    this.updateSelectedTextarea(textareaName);
  }

  @action
  set_active_step(stepName) {
    this.activeStep = stepName;
    this.updateCurrentStatusForStep(stepName);
  }

  @action
  get_university_details() {
    const resp = this.sopApi.get_university_details();
    return resp;
  }

  @action
  get_course_details(payload) {
    const resp = this.sopApi.get_course_details(payload);
    return resp;
  }

  @action
  updateSelectedUniversity(value) {
    this.selecteduniversitiy = value;
  }

  @action
  updateSelectedDegree(value) {
    this.selectedDegree = value;
  }

  @action
  updateSelectedCourse(value) {
    this.selectedCourse = value;
  }

  @action
  clear_newCompose_object() {
    this.newComposeObject = {};
  }

  @action
  updateNewComposeObject(key, value) {
    this.newComposeObject[key] = value;
  }

  @action
  set_new_composition_object(data: object) {
    this.newComposeObject = data;
  }

  @action
  update_sop_json(json) {
    this.sopjsonData = json;
  }

  @action
  async update_sop_workspace(folderId, fileId) {
    this.set_selected_textarea(
      this.selected_text_area.name,
      this.selected_text_area.template_id,
      ''
    );

    const cloneSopJsonData = cloneDeep(toJS(this.sopjsonData));
    for (let key in cloneSopJsonData) {
      if (
        key === 'reason_pursue_course' ||
        key === 'reason_for_chosen_university' ||
        key === 'short_term_goals' ||
        key === 'long_term_goals'||
        key === 'curricular_activities' ||
        key === 'obstacles_or_hardships' ||
        key === 'skills' ||
        key === 'reason_stronger_candidate' ||
        key === 'reasons_for_admissions_committee' ||
        key === 'switching_fields_from_prev_education' ||
        key === 'internships' ||
        key === 'academic_background' ||
        key === 'undergraduate_projects' ||
        key === 'work_experience' 
      ) {
        cloneSopJsonData[key] = sanitizeHtml(cloneSopJsonData[key], sanitizeConf);
      } else if (key === 'motivation') {
        for (let keyMotivation in cloneSopJsonData[key]) {
          if (keyMotivation === 'value') {
            cloneSopJsonData[key][keyMotivation] = sanitizeHtml(
              cloneSopJsonData[key][keyMotivation],
              sanitizeConf
            );
          }
        }
      } else if (key === 'qualifications' || key === 'contributions') {
        for (let i = 0; i < cloneSopJsonData[key].length; i++) {
          for (let keyElement in cloneSopJsonData[key][i]) {
            if (keyElement === 'value') {
              // @ts-ignore
              cloneSopJsonData[key][i][keyElement] = sanitizeHtml(
                cloneSopJsonData[key][i][keyElement],
                sanitizeConf
              );
            }
          }
        }
      }
    }

    this.sopjsonData = cloneSopJsonData;

    const payloadData = {
      gentool: '152',
      folderid: folderId ? folderId : 0,
      documentid: fileId ? fileId : 0,
      payload: this.sopjsonData,
      issubmitted: false,
    };
    const resp = await this.sopApi.update_workspace(payloadData);
    if(resp.status == 200) {
      this.sopId = resp.data.documentid
      this.edit_clicked = false
    }
    return resp;
  }

  @action
  async save_sop_file(activeFolder, parentId) {
    const payloadData = {
      parent: activeFolder,
      name: '',
    };
    const resp = await this.sopApi.create_file(payloadData);
    runInAction(() => {
      if (resp.status === 200) {
        this.update_sop_workspace(resp.data.folder_id, resp.data.file_id);
        this.sopId = resp.data.file_id;
        this.folderid=resp.data.folder_id;
      }
    });
  }

  @action
  async getTemplates(payload) {
  
    const resp = await this.sopApi.get_templates(payload);
    runInAction(() => {
      if (resp.status === 200) {
        this.templatesForScreen= resp.data;
        console.log(toJS(this.templatesForScreen), 'templates')
      }
    });
  }

  @action
  async get_details_of_university() {
    const payloadData = {
      kid: safe_get(this.sopjsonData, 'university.value', ''),
    };
    const resp = await this.sopApi.get_details_of_university(payloadData);
    runInAction(() => {
      if (resp.status === 200) {
        if (resp.data && resp.data.length > 0 && resp.data[0]['summary']) {
          this.college_summary = resp.data[0]['summary'];
        }
      }
    });
  }

  @action
  toggle_show_template() {
    this.showTemplate = !this.showTemplate;
  }

  @action
  set_sop_id(id) {
    this.sopId = id;
  }

  @action async get_generated_sop(sopId,folderId) {
    this.loading = true;
    const resp = await this.sopApi.get_generated_sop(sopId,folderId)
    this.generatedOutput = resp.data.output;
    this.append_paragraph_to_editor_json(resp.data.output);
  }

  @action
  async get_sop_workspace(sopId, folderId) {
    this.loading = true;
    const payloadData = {
      toolid: '152',
      documentid: sopId ? sopId : 0,
      folderid: folderId ? folderId : 0,
      payload: {},
      issubmitted: false,
    };
    const resp = await this.sopApi.get_workspace(payloadData);
    runInAction(() => {
      if (resp.status === 200) {
        this.loading = false;
        if (resp.data && resp.data.state) {
          this.sopjsonData = safe_get(resp.data, 'state', this.sopjsonData);
          this.sopName = resp.data.file_name;
          this.breadCrumbs = resp.data.bread_crums;
          stepsArr.map((step) => {
            this.markCompleted(step);
          });
        }
        this.jsonDataLoaded = true;
        this.availableCredits = resp.data.availableCredits;
      }
    });
  }

  @action
  updateCardSelected(value) {
    this.cardSelected = value;
  }

  @action
  updateCardDescription(value) {
    this.selectedCardDescription = value;
  }

  @action
  update_selected_card(id) {
    this.selectedCard = id;
  }

  @action
  async generatecontent() {
    this.loading = true;
    const qualArr:any = [];
    const contArr = [];

    if (safe_get(this.sopjsonData, 'qualifications', []).length > 0) {
      this.sopjsonData.qualifications.map((item) => qualArr.push(item['value']));
    }
    if (safe_get(this.sopjsonData, 'contributions', []).length > 0) {
      this.sopjsonData.contributions.map((item) => {
        contArr.push(item['value']);
      });
    }
    const payload = {
      course: safe_get(this.sopjsonData, 'course.label', ''),
      university: safe_get(this.sopjsonData, 'university.label', ''),
      summary: this.college_summary,
      degree: safe_get(this.sopjsonData, 'degree.label', ''),
      word_count: '1000',
      reason_for_doing_course: safe_get(this.sopjsonData, 'reason_for_doing_course', ''),
      reason_pursue_course: safe_get(this.sopjsonData, 'reason_pursue_course', ''),
      reason_for_chosen_university: safe_get(this.sopjsonData, 'reason_for_chosen_university', ''),
      motivation: safe_get(this.sopjsonData, 'motivation.value', ''),
      short_term_goals: safe_get(this.sopjsonData, 'short_term_goals', ''),
      long_term_goals: safe_get(this.sopjsonData, 'long_term_goals', ''),
      qualifications: qualArr,
      contributions: contArr,
      switching_fields_from_prev_education: safe_get(this.sopjsonData, 'switching_fields_from_prev_education', ''),
      academic_background: safe_get(this.sopjsonData, 'academic_background', ''),
      undergraduate_projects: safe_get(this.sopjsonData, 'undergraduate_projects', ''),
      internships: safe_get(this.sopjsonData, 'internships', ''),
      work_experience: safe_get(this.sopjsonData, 'work_experience', ''),
      curricular_activities: safe_get(this.sopjsonData, 'curricular_activities', ''),
      reasons_for_admissions_committee: safe_get(this.sopjsonData, 'reasons_for_admissions_committee', ''),
      reason_stronger_candidate: safe_get(this.sopjsonData, 'reason_stronger_candidate', ''),
      skills: safe_get(this.sopjsonData, 'skills', ''),
      obstacles_or_hardships: safe_get(this.sopjsonData, 'obstacles_or_hardships', ''),
      toolid: 152,
      fileid: this.sopId,
      folderid: this.folderid,
    };


    const resp = await this.sopApi.generate_content(payload);
    if (resp.status === 200) {
      this.append_paragraph_to_editor_json(resp.data.response);
      this.generatedOutput = resp.data.response;
    } else {
      this.loading = false;
      toast.error(resp.data, { position: 'top-center' });
    }
    this.loading = false;
  }

  @action
  get_initial_json_for_editor() {
    return this.editor_data;
  }

  @action
  update_editor_data(data) {
    this.editor_data = data;
  }

  @action
  clear_sop_state() {
    this.sopjsonData = { ...this.defaultSopJsonData };
    this.generatedOutput = '';
    this.jsonDataLoaded = false;
    this.set_active_step('Step 1');
    this.editor_data = { blocks: [], entityMap: {} };
  }

  @action
  refresh_editor_id() {
    this.editor_id = makeid_alpa_numeric(6);
  }

  @action
  append_paragraph_to_editor_json(text) {
    const block = {
      data: {},
      depth: 0,
      entityRanges: [],
      inlineStyleRanges: [],
      key: makeid_alpa_numeric(5),
      text: text.replace(/<\/br>/g, ''),
      type: 'paragraph',
    };
    const data = this.editor_data;
    // @ts-ignore
    data['blocks'] = [block];
    this.update_editor_data(data);
    this.refresh_editor_id();
  }

  @action
  updateCompletedStatusForStep(stepName, isCompleted) {
    this.steps.map((step) => {
      if (step.id === stepName) {
        if (isCompleted) {
          step.completed = true;
        } else {
          step.completed = false;
        }
      }
    });
  }

  @action
  markCompleted(stepName) {
    switch (stepName) {
      case 'Step 1':
        if (
          !_.isEmpty(safe_get(this.sopjsonData, 'course', {})) &&
          !_.isEmpty(safe_get(this.sopjsonData, 'university', {})) &&
          !_.isEmpty(safe_get(this.sopjsonData, 'degree', {})) &&
          safe_get(this.sopjsonData, 'reason_pursue_course', '') !== '' && safe_get(this.sopjsonData,'reason_for_chosen_country','') !== ''
        ) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
      case 'Step 2':
        if (safe_get(this.sopjsonData, 'motivation.value', '')) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
      case 'Step 3':
        if (
          safe_get(this.sopjsonData, 'short_term_goals', '') !== '' &&
          safe_get(this.sopjsonData, 'long_term_goals', '') !== ''
        ) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
      case 'Step 4':
        if (safe_get(this.sopjsonData, 'switching_fields_from_prev_education', '')||
        safe_get(this.sopjsonData, 'academic_background', '')||
       safe_get(this.sopjsonData, 'undergraduate_projects', '')||
      safe_get(this.sopjsonData, 'internships', '')||
      safe_get(this.sopjsonData, 'work_experience', '')) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
      case 'Step 5':
        if (safe_get(this.sopjsonData, 'contributions', []).length > 0) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
    }
  }
}