import apiSauce from "./api-sauce";

export class SopApi {

  async create_file(payload): Promise<any> {
    return await apiSauce.apisauce.post("/library/create_file",JSON.stringify(payload))
  }

  async get_university_details(): Promise<any> {
    return await apiSauce.apisauce.post("/icc/alluniversities")
  }

  async get_course_details(payload): Promise<any> {
    return await apiSauce.apisauce.post("/icc/courses_by_universities", JSON.stringify(payload))
  }

  async update_workspace(payload): Promise<any> {
    return await apiSauce.apisauce.post("workspace/update_workspace", JSON.stringify(payload))
  }

  async get_workspace(payload): Promise<any> {
    return await apiSauce.apisauce.post("workspace/get_workspace", JSON.stringify(payload))
  }

  async generate_content(payload): Promise<any> {
    return await apiSauce.apisauce.post("gen/chat", JSON.stringify(payload))
  }

  
  async get_details_of_university(payload): Promise<any> {
    return await apiSauce.apisauce.post("icc/get_university", JSON.stringify(payload))
  }

  async get_generated_sop(fileId,folderId): Promise<any> {
    return await apiSauce.apisauce.get(`workspace/get_genearted_SOP?fileid=${fileId}&folderid=${folderId}`)
  }

  async get_templates(payload): Promise<any> {
    return await apiSauce.apisauce.post("templates/get_templates", JSON.stringify(payload))
  }
}
