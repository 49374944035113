import React from "react";
import {action, makeAutoObservable} from "mobx";
import {DashboardApi} from "../api/dashboard-api";
import {HomeIcon, UsersIcon} from "@heroicons/react/24/outline";
import {makePersistable} from "mobx-persist-store";
import _ from "lodash";
import SOPTool from "../icons/Tool.svg"
import YourLibrary from "../icons/Library.svg"
export class DashboardStore {
  dashboardNavigation = [
    {name: 'Your Library', href: '#', icon: YourLibrary, current: true},
    {name: 'SOP Tool', href: '#', icon: SOPTool, current: false},
  ]

  profileNavigation = [
    {name: 'Personal Details',current: true},
    {name: 'Plans & Pricing',current: false},
    // {name: 'Settings',current: false},
  ]

  showDashboardSideBar: boolean = false
  screenName: string = 'Your Library'
  modalWarningDialog: boolean = false
  modalConfirmNavigatePath: string = ''


  constructor(private dashboardApi: DashboardApi) {
    makeAutoObservable(this);
    makePersistable(this, {name: 'DashboardStore', properties: [], storage: window.localStorage});
  }

  @action
  updateCurrentStatusForDashboardNav(stepName) {
    this.dashboardNavigation.map((step) => {
      if (step.name === stepName) {
        step.current = true
        this.screenName = step.name
      } else {
        step.current = false
      }
    })
  }

  @action
  updateCurrentStatusForProfileNav(stepName) {
    this.profileNavigation.map((step) => {
      if (step.name === stepName) {
        step.current = true
        this.screenName = step.name
      } else {
        step.current = false
      }
    })
  }

  @action
  setShowDashboardSideBar(isShow) {
    this.showDashboardSideBar = isShow
  }

  @action
  toggleShowDashboardSideBar() {
    this.showDashboardSideBar = !(this.showDashboardSideBar)
  }

  @action
  setScreenName(screen_name) {
    this.screenName = screen_name
  }

  @action
  getActiveOption() {
    const matches = _.filter(this.dashboardNavigation, {current: true})
    return matches[0].name
  }

  @action
  getActiveProfileOption() {
    const matches = _.filter(this.profileNavigation, {current: true})
    return matches[0].name
  }

  @action
  setModalWarningDialog(flag) {
    this.modalWarningDialog = flag
  }

  @action
  setModalConfirmNavigatePath(path) {
    this.modalConfirmNavigatePath = path
  }

}
