import React from "react";
import { ClipLoader} from "react-spinners";

export const Loading = () => {
  return (

    <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 " style={{zIndex: 99999}}>
      <span className="text-green-500 opacity-75 top-1/2 right-8 my-0 mx-auto block relative w-0 h-0"
            style={{top: '48%'}}>
        <ClipLoader
          color={"green"}
          loading={true}
          // cssOverride={override}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </span>
    </div>
  )
}
