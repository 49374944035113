

import {UserStore} from "./user-store";



import {SopStore} from "./sop-store";
import {SopApi} from "../api/sop-api";
import {DashboardStore} from "./dashboard-store";
import { PaymentStore } from './payment-store';
import {DashboardApi} from "../api/dashboard-api";
import { PaymentApi } from '../api/payment-api';





import { UserApi } from '../api/user-api';



export const RootStore = {
 
  sopStore: new SopStore(new SopApi()),
  dashboardStore: new DashboardStore(new DashboardApi()),
  paymentStore: new PaymentStore(new PaymentApi()),
  userStore: new UserStore (new UserApi())
};
