import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Loading } from './components/Loading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './pages/footer';
import cambriLogo from './icons/Cambri-blue.png'

/** LOAD ROUTES **/
// Set up all routers as lazy routes. THis is important because when the app loads, we want the landing page to split load quickly for SEO purposes.
// Hence make it default to setup all routes as lazy routes.

const LandingPage = lazy(() => import('./pages/home_page/index'));
const AboutUsPage = lazy(() => import('./pages/about-us/index'));
const ContactUsPage = lazy(() => import('./pages/contact-us/index'));
const BlogsPage = lazy(() => import('./pages/blogs/index'));
const IeltsPage = lazy(()=>import('./pages/products/index'));
const Payments = lazy(()=>import('./pages/home_page/payments/paymentSection'));
const Navbar = lazy(()=>import('./pages/home_page/navmenu'));


/*******/

export const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route index element={<LandingPage />} />
          <Route path='/aboutus' element={<AboutUsPage />} />
	        <Route path='/contactus' element={<ContactUsPage />} />
          <Route path='/ielts' element={<IeltsPage/>} />
          <Route path='/blogs' element={<BlogsPage />} />
          <Route path='/payments' element={
          <>
           <img src={cambriLogo} alt="logo" className='m-auto'/>
           <Payments/>
           <Footer page={"payments"} />
          </>
          } />
        </Routes>
      </Suspense>
      <ToastContainer />
    </BrowserRouter>
  );
};